/* @import url('https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@400;500&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,700;1,100;1,200;1,300;1,400;1,700&display=swap'); */

.question-practice .question-container {
  margin: 10px;
  background-color: white;
  border-radius: 15px;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.question-practice .question-number {
  display: block;
  font-weight: 700;
  padding: 5px 0;
  border-bottom: 1px solid #b1b1b1;
  margin-bottom: 12px;
  font-size: 1rem;
  /* position: fixed; */
  width: 100%;
}

.question-practice {
  margin: 5px;
  font-size: 14px;
  color: #333;
  display: flex;
  border: 4px solid #4a7a9d;
  height: 98.5vh;
}

.question-practice .question-text {
  text-align: justify;
  font-weight: 600;
  overflow: hidden;
  word-wrap: break-word;
  line-height: 1.5rem;
}

.question-practice .options-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.question-practice .options-grid {
  overflow: auto;
}

.question-practice .button-container {
  border: 1px solid #bababa;
}

/* .question-practice .question-option {
  min-height: 63.5vh;
  max-height: 63.5vh;
} */

.question-practice .test-button {
  color: black;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #bababa;
  background-color: none;
}

.question-practice .modal-button {
  /* padding: 3px;
  margin-top: 2px; */
  border-radius: 5px;
  border: 1px solid #bababa;
}

/* .question-practice .question-box {
  padding-right: 12px;
} */

.question-practice .next-button {
  background-color: #2f72b7;
  color: white;
}

.question-practice .test-title {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-practice .option-box {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-top: 0.7rem;
  cursor: pointer;
  position : relative;
}

.question-practice .option-box:hover{
  /* background-color: #f4f4f4; */
}

.question-practice .options-grid .incorrect {
  color: white;
  background: red;
}

.question-practice .options-grid .correct {
  /* border: 2px solid green !important; */
  color: black;
}

/* .question-practice .correct .option-alphabet {
    background-color: green;
    color: white;
} */

.question-practice .incorrect .option-alphabet {
  /* background-color: white; */
  color: white;
}

.question-practice .option-alphabet {
  font-weight: bold;
  color: #444;
  margin-right: 15px;
  border: 1px solid #bababa;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-practice .correct-answer {
  margin-left: auto;
}

.question-practice .incorrect-answer {
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  .question-practice .question-box {
    width: 100%;
    margin: 10px 0;
  }
  .question-practice .option-box {
    margin: 10px 0;
  }
  .question-practice .options-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.question-practice .question-image {
  max-width: 75%;
  max-height: 75%;
  object-fit: cover;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.question-practice .p {
  justify-content: baseline;
  align-items: baseline;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

.question-practice .pagination {
  /* Styles for pagination */
  display: flex;
  justify-content: center;
  color: black;
  flex-wrap: wrap;
  align-items: center;
}

.question-practice .page-button {
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border: 2px solid #79090b;
  background-color: #ffffff;
  color: #333;
  border-radius: 10px;
  transition: background-color 0.2s ease;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 0.5rem; */
  margin: 5px;
}

.question-practice .page-button.active {
  background-color: #79090b;
  color: #fff;
  font-weight: bold;
}

.question-practice .page-button.disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #c9c4c4;
}

.question-practice .explanation-wrapper .explanation p h6 {
  color: #444;
  line-height: 2rem;
}

.question-practice .explanation-wrapper .explanation {
  border: 1px solid #bababa;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff7f7;
}

.question-practice .question {
  /* min-width: 64%;
  max-width: 64%;
  padding: 0 5px 0 0;
  margin-right: 2%;
  border-right: 1px solid #abc;
  max-height: 64vh;
  overflow: auto; */
}

.question-practice .item-content {
  max-height: 68vh;
  overflow: auto;
  padding: 0 12px;
}

.question-practice .question-text-container {
  text-align: justify;
}

@media screen and (max-width: 700px) {
  .question-practice .page-button {
    padding: 0.5rem;
    justify-content: center;
  }
}

.question-practice h6 {
  margin-bottom: 0px;
  line-height: 1.5rem;
}

.question-practice .question-box .para-type {
  /* padding: 2rem 2rem 0 2rem; */
  display: flex;
}

.question-practice .question-box .para-type .item-passage {
  min-width: 60%;
  max-width: 60%;
  min-height: 68vh;
  max-height: 68vh;
  overflow: auto;
  padding-right: 12px;
  border-right: 1px solid #bababa;
}
.question-practice .question-box .para-type .item-content {
  min-width: 40%;
  max-width: 40%;
}
.toggle-button {
  transition: background-color 0.3s ease;
  /* Add other styles for your button */
}

.toggle-button.toggled {
  background-color: #79090b;
  /* Change the background color when toggled */
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  /* Adjust the duration and easing as needed */
}

.content.visible {
  max-height: 1000px;
  /* Adjust the max height as needed */
}

.accordion-button:focus {
  box-shadow: none;
}

@media screen and (max-width: 800px) {
  .question-practice .question-box .question-option {
    padding: 2rem 1rem 0 1rem;
  }
}

.question-practice-v2 .LeftBlock {
  z-index: 0;
  position: absolute;
  bottom: 0px;
  top: 0px;
}

.question-practice-v2 .toggle-side-bar-btn {
  position: absolute;
  width: 16px;
  height: 50px;
  background: #333;
  top: 80px;
  bottom: 0;
  border: none;
  outline: 0;
  border-radius: 2px 0 0 2px;
  /* margin: auto 0 auto -8px; */
  color: #fff;
  padding: 0;
  font-size: 80%;
  display: block;
  cursor: pointer;
  left: -15px;
}

.question-practice-v2 .question-pallet {
  border: 2px solid #000;
  border-right: none;
  background: #e5f6fd;
  z-index: 2;
  max-height: 68vh;
  overflow: hidden;
}

.question-practice-v2 .Legend {
  background: #fff;
  /* max-height: 27vh;
  min-height: 27vh; */
}

/* .question-practice-v2 .pallet-section-title{
  max-height: 10vh;
  min-height: 10vh;
} */

.question-practice-v2 .legend-block {
  display: flex;
  flex-flow: wrap;
  padding: 10px;
}

.question-practice-v2 .legend-item {
  margin-bottom: 10px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.question-practice-v2 .lg-answered span,
.question-practice-v2 .lg-not_answered span {
  float: left;
  height: 26px;
  margin-right: 10px;
  width: 29px;
  color: #fff;
}
.question-practice-v2 .legend-item span {
  background: url(../../assets/images/questions-sprite.png) no-repeat;
  color: #262626;
  line-height: 15px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}

.question-practice-v2 .lg-review_answered span,
.question-practice-v2 .lg-review span {
  float: left;
  height: 28px;
  margin-right: 10px;
  color: #fff;
  padding-top: 5px;
}

.question-practice-v2 .legend-item.lg-review_answered {
  margin-bottom: 6px;
  width: 100%;
}

.question-practice-v2 .lg-review_answered span,
.question-practice-v2 .lg-review span {
  float: left;
  height: 28px;
  margin-right: 10px;
  color: #fff;
  padding-top: 5px;
}

.question-practice-v2 .qp-title {
  background: #4e85c5 none repeat scroll 0 0;
  color: #fff;
  height: 31px;
  line-height: 31px;
  padding-left: 10px;
  font-weight: 700;
  overflow-x: hidden;
}

.question-practice-v2 .qp-label {
  padding: 10px;
  font-weight: 700;
}

.question-practice-v2 .pallet-list-body {
  overflow-y: auto;
  /* min-height: calc(100vh - 476px); */
  /* min-height: calc(var(--vh, 1vh) * 100 - 532px); */
  /* max-height: calc(100vh - 476px); */
  /* max-height: calc(var(--vh, 1vh) * 100 - 532px); */
  /* min-height: 184px; */
  padding: 0 3px;
  /* max-height: 184px; */
  min-height: 28vh;
  max-height: 28vh;
  
}

.testknock-left .question-box {
  min-height: calc(var(--vh, 1vh) * 100 - 220px);
  max-height: calc(var(--vh, 1vh) * 100 - 220px);
  overflow: hidden;
}

.border-wrapper {
  border: 1px solid #bababa;
  /* margin-bottom: 0.5rem; */
}

.question-practice-v2 .pallet-item {
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
}

.question-practice-v2 .optionitem ,.question-practice-v2 label{
  cursor: pointer;

}

.question-practice-v2 .pallet-item span {
  background: url(../../assets/images/questions-sprite.png) no-repeat;
  color: #fff;
  cursor: pointer;
  float: left;
  font-size: 1.417em;
  font-weight: 400;
  height: 43px;
  line-height: 42px;
  margin-bottom: 10px;
  margin-right: 6px;
  text-align: center;
  width: 50px;
}

.question-practice-v2 span.not_visited {
  background-position: -157px -4px;
  color: #474747;
  height: 43px;
  line-height: 43px;
}

.question-practice-v2 span.answered {
  background-position: -4px -5px;
}

.question-practice-v2 span.review {
  background-position: -108px -1px;
  height: 50px;
  line-height: 50px;
  margin-bottom: 3px;
}

.question-practice-v2 span.review_answered {
  background-position: -203px -49px;
  height: 53px;
  line-height: 50px;
  margin-bottom: 0;
  width: 53px;
}

.question-practice-v2 span.not_answered {
  background-position: -57px -6px;
  width: 49px;
  height: 43px;
}

.question-practice-v2 .lg-review_answered span {
  background-position: -172px -55px;
  line-height: 15px;
  width: 30px;
}

.question-practice-v2 .lg-review span {
  background-position: -75px -54px;
  line-height: 18px;
  width: 28px;
}

.question-practice-v2 .lg-not_visited span {
  background-position: -107px -56px;
  float: left;
  height: 26px;
  line-height: 21px;
  margin-right: 10px;
  width: 29px;
  color: #000;
  padding-top: 2px;
}

.question-practice-v2 .lg-answered span {
  background-position: -7px -55px;
  line-height: 20px;
  padding-top: 3px;
  color: white;
}

.question-practice-v2 .lg-not_answered span {
  background-position: -42px -55px;
  line-height: 20px;
  padding-top: 3px;
  color: white;
}

/* calculator css */

.ct-calc-normal {
  width: 256px;
}

.ct-calc {
  background: none repeat scroll 0 0 #dadada;
  border-radius: 5px;
  box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #aaa;
  border-bottom: 7px solid #999;
  position: fixed;
  z-index: 99;
}

.ct-calc-head {
  display: block;
  width: 100%;
  height: 36px;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #4286f3;
  color: #fff;
  cursor: move;
}

.ct-calc-title {
  float: left;
}

.ct-calc-actions {
  float: right;
}

.ct-calc-actions i {
  margin-left: 10px;
  font-size: 18px;
  cursor: pointer;
}

.fa-minus:before {
  content: "\f068";
}

.fa-times:before {
  content: "\f00d";
}

.ct-calc-body {
  padding: 10px 0;
  cursor: move;
  height: 100%;
}

.ct-calc-normal iframe {
  height: 282px;
  border: 0;
  overflow: auto;
  margin: auto;
}

.catbody .ct-calc iframe {
  width: 100%;
}

.question-practice .ct-calc {
  background: none repeat scroll 0 0 #dadada;
  border-radius: 5px;
  box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #aaa;
  border-bottom: 7px solid #999;
  position: fixed;
  z-index: 99;
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.keyboard {
  width: 145px;
  background-color: #f4f4f4;
  padding: 5px 20px;
  font-size: 15px;
  text-align: center;
  margin-bottom: 12px;
  position: absolute;
  top: 50%;
  left: 0;
}

.keyitems {
  text-align: center;
}

.keyitems span {
  min-width: 24px;
  background-color: #eee;
  padding: 3px 8px;
}

.backspace,
.clearall,
.keyitems span {
  border: 1px solid #666;
  display: inline-block;
  color: #000;
  font-family: arial;
  font-weight: 700;
  margin: 2px;
  cursor: pointer;
  border-radius: 6px;
}

.keyitems span:hover {
  background-color: #cee9fc;
}

.offline {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.offline span {
  background: #c50a0a;
  color: #fff;
  padding: 6px;
  border-radius: 6px;
}

.ct-right {
  width: 100%;
  border-left: 1px solid #c3c3c1;
  height: 112px;
  position: relative;
  display: flex;
}

.ct-profile-image {
  padding: 7px;
}

.ct-profile-image img {
  border: 1px solid #c3c3c1;
  border-radius: 1px;
  height: 99px;
  width: 89px;
}

.ct-username {
  font-size: 16px;
  font-weight: 700;
  margin: 10px auto;
}

.calc-icon {
  background: url(https://kananprep-assets.s3.ap-south-1.amazonaws.com/testengine/testengine-items/catlayout/Icon-sprite.png)
    no-repeat -148px -62px;
  width: 17px;
  height: 23px;
  display: inline-block;
  cursor: pointer;
}

.ct-timer-block {
  border-bottom: 1px solid #c3c3c1;
  height: 30px;
  line-height: 28px;
}
.ct-timer-left {
  float: left;
  margin-left: 8px;
}

.ct-timer-right {
  float: right;
  font-size: 1.2em;
  margin-right: 8px;
  font-weight: 700;
}

.ct-marks-sections {
  height: 30px;
  line-height: 29px;
  text-align: right;
  padding-right: 10px;
  border-bottom: 1px solid #c3c3c1;
}

.testknock-left {
  width: 82%;
}

.testknock-right {
  width: 18%;
}
