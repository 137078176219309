body {
  font-family: Arial, Helvetica, sans-serif !important;
}

a {
  text-decoration: none;
}

h6 {
  margin-bottom: 0px;
}

/* .level-0{
  max-height: 4vh;
  min-height: 4vh;
}
.level-1{
  max-height: 15vh;
  min-height: 15vh;
}
.level-2{
  max-height: 79vh;
  min-height: 79vh;
}
.level-3-navigation-button{
  min-height: 7vh;
  max-height: 7vh;
}
.level-3-question , .level-3-pallet{
  min-height: 70vh;
  max-height: 70vh;
}
 */

.level-3-question {
  min-height: 70vh !important;
  max-height: 70vh !important;
}

.minimal-space {
  height: 3px;
  width: 100%;
}

.text-spacing {
  white-space: pre-wrap;
}

/* .width10 {
  width: 50% !important;
} */
