.container {
      padding: 20px;
      border-radius: 10px;
      background-color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    }
    
    .metric-row {
      height: 60px;
      padding: 10px 0;
      border-bottom: 1px solid #f0f0f0;
    }
    
    .metric-icon-container {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f8f9fa;
      border: 1px solid #e9ecef;
      border-radius: 6px;
    }
    
    .metric-label {
      font-weight: 500;
      color: #495057;
    }
    
    .comparison-row {
      height: 60px;
      padding: 10px 0;
    }
    
    .dashed-line {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      border-top: 2px dashed #dee2e6;
      z-index: 0;
    }
    
    .topper-values {
      display: flex;
      gap: 40px;
      margin-left: 20px;
      z-index: 1;
    }
    
    .topper-circle-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .topper-rank-badge {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #ff9800;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 14px;
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
    
    .topper-circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      border: 2px solid #ff9800;
      color: #ff9800;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      z-index: 1;
    }
    
    .topper-avatar {
      width: 20px;
      height: 15px;
      background-color: #4d7ce0;
      border-radius: 50% 50% 0 0;
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
    }
    
    .user-value-container {
      margin-right: 20px;
      z-index: 1;
    }
    
    .user-value-label {
      font-size: 12px;
      color: #6c757d;
      margin-bottom: 5px;
    }
    
    .user-value-circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      border: 2px solid #4d7ce0;
      color: #4d7ce0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
    
    .user-avatar {
      width: 20px;
      height: 15px;
      background-color: #4d7ce0;
      border-radius: 50% 50% 0 0;
      margin-top: 2px;
    }
    
    /* Custom icons */
    .gauge {
      position: relative;
      width: 26px;
      height: 13px;
      background: linear-gradient(to right, #ff0000, #ffff00, #00ff00);
      border-radius: 13px 13px 0 0;
      overflow: hidden;
    }
    
    .gauge-needle {
      position: absolute;
      height: 13px;
      width: 2px;
      background-color: #333;
      left: 50%;
      bottom: 0;
      transform-origin: bottom center;
      transform: rotate(30deg);
    }
    
    .attempt-rate-img {
      width: 30px;
      height: 30px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10' fill='%23e0e0e0'/%3E%3Cpath d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' fill='%233498db'/%3E%3C/svg%3E");
      background-size: contain;
    }